import React from 'react';
import { Navbar, Form, FormControl, Button } from 'react-bootstrap';

const Header = (props) => {
    return (
        <Navbar bg="light">
            <Navbar.Brand href="#home" className="mr-auto">
                <img
                    alt=""
                    src="/121ecommerce.svg"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                />{' '}
                121 Extensions panel
            </Navbar.Brand>
            <Form inline className="d-none d-sm-block">
                <FormControl
                    type="text"
                    placeholder="Search"
                    className="mr-sm-2"
                />
                <Button variant="outline-info">Search</Button>
            </Form>
        </Navbar>
    );
};

export default Header;
