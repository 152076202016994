import './App.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './components/Header';
import { Container, Row } from 'react-bootstrap';

import List from './components/List';
import withListLoading from './components/WithListLoading';

function App() {
    const ListLoading = withListLoading(List);
    const [appState, setAppState] = useState({
        loading: false,
        repos: null,
    });

    useEffect(() => {
        setAppState({ loading: true });
        const apiUrl = `https://extensions-panel.121ecommerce.com/extensions.json`;
        axios.get(apiUrl).then((extensions) => {
            const allExtensions = extensions.data;
            setAppState({
                loading: false,
                extensions: allExtensions,
            });
        });
    }, [setAppState]);

    return (
        <Container>
            <Header />
            <Row>
                <ListLoading
                    isLoading={appState.loading}
                    extensions={appState.extensions}
                />
            </Row>
        </Container>
    );
}

export default App;
