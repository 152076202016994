import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'react-bootstrap';
import {
    faCodeBranch,
    faCalendar,
} from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';

const Card = (props) => {
    const { extension } = props;

    return (
        <Col className="col-12 col-sm-8 col-md-6 col-lg-4">
            <div className="card h-350">
                <div className="card-img-container">
                    <img
                        className="card-img"
                        src={extension.image}
                        alt={extension.name}
                    />
                    <div className="overlay">
                        {extension.new && (
                            <span className="badge bg-success text-uppercase">
                                New
                            </span>
                        )}
                    </div>
                </div>
                <div className="card-body">
                    <h4 className="card-title">{extension.name}</h4>
                    <small>
                        <FontAwesomeIcon icon={faCodeBranch} />{' '}
                        {extension.magento_versions}
                    </small>
                    <p className="card-text">
                        {extension.description}
                    </p>
                    {extension.button_link && (
                        <a
                            href={extension.button_link}
                            className="btn btn-primary"
                            rel="noreferrer"
                            target="_blank"
                        >
                            Try it!
                        </a>
                    )}
                </div>
                <div className="card-footer d-flex justify-content-between">
                    <div className="views">
                        <small>
                            <FontAwesomeIcon icon={faCalendar} />{' '}
                            <Moment format="MMM Do YYYY">
                                {extension.updated_date}
                            </Moment>
                        </small>
                    </div>
                    <div className="stats">
                        {extension.current_version}
                    </div>
                </div>
            </div>
        </Col>
    );
};

export default Card;
